import Banner from '@components/Banner';
import Page from '@components/Layout/Page';
import Link from '@components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import Card from '@components/Card';

// Square image creation:
// https://www.gatsbyjs.org/docs/working-with-images/#using-fragments-to-standardize-formatting
const TeamPage = () => {
    const data = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "team.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1300) {
						...GatsbyImageSharpFluid
					}
				}
			}
			allTeamYaml(sort: { fields: name, order: ASC }) {
				nodes {
					name
					title
					url
					image {
						childImageSharp {
							fluid(maxWidth: 400, maxHeight: 400) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			allDescriptionsYaml {
				nodes {
					team
					description
				}
			}
		}
	`);
    return (
        <Page
            title="Team"
            banner={
                <Banner image={{ fluid: data.file.childImageSharp.fluid }}>
                    <h1 className="text-5xl mb-3 font-bold">The Team</h1>
                    <div className="max-w-2xl mx-auto">
                        <p>
                            Our team comprises of innovative self starters who constantly want to empower the
                            community. From computer scientists to entrepeneurs, our team has people of all
                            backgrounds and experiences. An important element of HackCU is that all of our events
                            are led exclusively by students. We teach and learn from our team members how to run
                            and manage some of the largest technology events in the country. With this experience,
                            our team members and alumni go on to work for some of the biggest names in tech and
                            some even start their own companies.
                        </p>
                    </div>
                </Banner>
            }
        >
            <div className="flex flex-col md:flex-row-reverse mt-3">
                <div className="w-full md:w-1/4 px-4">
                    <div className="bg-secondary-500 rounded-md mt-4 px-5 py-4">
                        <h1 className="text-center font-bold text-3xl mb-3">Join the Team</h1>
                        <p>
                            Applications for the 2025-2026 school year are now open! If you are interested in
                            joining any of the Marketing, Logistics, Workshops, Tech, or Finance sub-teams, please
                            apply with the following &nbsp;
                            <Link className="text-primary-500 font-bold" outside to="/recruitment">
                                form
                            </Link>
                            ! Take a look at the team descriptions and email us if you have any questions.
                        </p>
                    </div>
                </div>

                <div className="w-full sm:w-1/1 md:w-3/4 mb-3">
                    <div className="flex flex-wrap mt-4">
                        <Card>
                            <div className="flex flex-wrap ml-3 mr-3">
                                {data.allDescriptionsYaml.nodes.map((subteam) => {
                                    const teams = (
                                        <>
                                            <h1 className="text-6xl mb-2 font-bold">{subteam.team}</h1>
                                            <p>{subteam.description}</p>
                                        </>
                                    );

                                    return (
                                        <div className="w-1/1 sm:w-1/2 md:w-1/2" key={subteam.team}>
                                            {teams}
                                        </div>
                                    );
                                })}
                            </div>
                        </Card>
                    </div>
                    <h1 className="font-bold my-3 text-5xl">Current Team</h1>
                    <div className="flex flex-wrap">
                        {data.allTeamYaml.nodes.map((person) => {
                            let body = (
                                <>
                                    {!!person.image && (
                                        <Image
                                            style={{ borderRadius: '100%' }}
                                            fluid={person.image.childImageSharp.fluid}
                                        />
                                    )}
                                    <h2 className="mt-2 font-bold text-2xl">{person.name}</h2>
                                    <p>{person.title}</p>
                                </>
                            );

                            if (person.url) {
                                body = (
                                    <Link
                                        // Removes styling
                                        className=""
                                        outside
                                        to={person.url}
                                    >
                                        {body}
                                    </Link>
                                );
                            }

                            return (
                                <div className="w-1/2 sm:w-1/3 md:w-1/5 text-center py-1 px-2" key={person.name}>
                                    {body}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default TeamPage;
